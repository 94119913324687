<template>
  <div class="mockup">
    <div
      ref="gameContainer"
      style="
        max-width: 480px;
        padding: 0;
        max-height: 100vh !important;
        margin: auto;
      "
    ></div>
  </div>
</template>

<script>
import Phaser from "phaser";
import { mapState } from "vuex";
import router from "@/router";

export default {
  name: "ABC2",
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    this.initializeGame();
  },
  methods: {
    initializeGame() {
      const config = {
        backgroundColor: 0xffffff,
        type: Phaser.AUTO,
        scale: {
          parent: this.$refs.gameContainer,
          mode: Phaser.Scale.FIT,
          autoCenter: Phaser.Scale.CENTER_BOTH,
          width: 480,
          height: 854,
        },
        scene: {
          preload: function () {
            this.load.image(
              "imgABC",
              require("@/mockups/ABC/book-1/assets/images/0 2.png")
            );
            this.load.image(
              "imgBAL",
              require("@/mockups/ABC/book-1/assets/images/left.png")
            );
            this.load.image(
              "imgBAR",
              require("@/mockups/ABC/book-1/assets/images/right.png")
            );
            this.load.image(
              "imgBP",
              require("@/mockups/ABC/book-1/assets/images/pause.png")
            );
          },
          create: function () {
            this.add.image(240, 320, "imgABC").setScale(0.35);

            this.add
              .text(240, 510, "Hamochi is always busy.", {
                fontFamily: "roboto, sans-serif",
                fontSize: 20,
                color: "#000000",
              })
              .setOrigin(0.5);

            this.add
              .text(240, 580, "Hamochi 的一天非常忙碌。", {
                fontFamily: "roboto, sans-serif",
                fontSize: 20,
                color: "#000000",
              })
              .setOrigin(0.5);

            this.add
              .image(160, 750, "imgBAL")
              .setScale(0.2)
              .setInteractive({ useHandCursor: true })
              .on("pointerdown", function () {
                router.push({
                  name: "ABCScreen1",
                });
              });

            this.add.image(240, 750, "imgBP").setScale(0.2);
            this.add
              .image(320, 750, "imgBAR")
              .setScale(0.2)
              .setInteractive({ useHandCursor: true })
              .on("pointerdown", function () {
                router.push({
                  name: "ABCScreen3",
                });
              });

            this.add
              .text(240, 830, "Page 2 of 100", {
                fontFamily: "roboto, sans-serif",
                fontSize: 14,
                color: "#000000",
              })
              .setOrigin(0.5);
          },
          update: function () {
            // Game logic here
          },
        },
      };

      this.game = new Phaser.Game(config);
    },
  },
  beforeDestroy() {
    if (this.game) {
      this.game.destroy(true);
    }
  },
};
</script>

<style scoped>
.mockup {
  background-color: white;
}
</style>